import moment from 'moment'
import { ref } from '@vue/composition-api'
import { BFormInput } from 'bootstrap-vue'

export async function promptSplit() {
  const messageVNode = this.$createElement('div', {
    class: ['p-1'],
    domProps: {
      innerHTML: this.$t('NotificationOfNeed.MultiSaveText'),
      style: 'font-size: 16px',
    },
  })

  const title = this.$t('NotificationOfNeed.MultiSaveTitle')

  const split = await this.$bvModal.msgBoxConfirm([messageVNode], {
    title,
    okVariant: 'outline-primary',
    cancelVariant: 'outline-secondary',
    okTitle: this.$t('NotificationOfNeed.MultiSaveFill'),
    cancelTitle: this.$t('NotificationOfNeed.MultiSaveSplit'),
    hideHeaderClose: false,
    centered: true,
    'body-class': 'p-2',
  })

  return split
}

export async function promptSuccessively(suggestedValue) {
  const value = ref(suggestedValue)
  const messageVNode = this.$createElement('div', {}, [
    this.$createElement('div', {
      class: ['p-1'],
      domProps: {
        innerHTML: this.$t('NotificationOfNeed.SuccessivelyText'),
        style: 'font-size: 16px',
      },
    }),
    this.$createElement(BFormInput, {
      props: { value: value.value },
      on: {
        input: event => {
          value.value = event
        },
      },
    }),
  ])

  const title = this.$t('NotificationOfNeed.SuccessivelyTitle')

  const choice = await this.$bvModal.msgBoxConfirm([messageVNode], {
    title,
    okVariant: 'outline-primary',
    cancelVariant: 'outline-secondary',
    okTitle: this.$t('Global.Next'),
    cancelTitle: this.$t('Global.Retreat'),
    hideHeaderClose: false,
    centered: true,
    'body-class': 'p-2',
  })

  return {
    choice,
    value: parseInt(value.value, 10),
  }
}

export async function promptParallel() {
  const messageVNode = this.$createElement('div', {
    class: ['p-1'],
    domProps: {
      innerHTML: this.$t('NotificationOfNeed.ParallelText'),
      style: 'font-size: 16px',
    },
  })

  const title = this.$t('NotificationOfNeed.ParallelTitle')

  const split = await this.$bvModal.msgBoxConfirm([messageVNode], {
    title,
    okVariant: 'outline-primary',
    cancelVariant: 'outline-secondary',
    okTitle: this.$t('NotificationOfNeed.Parallel'),
    cancelTitle: this.$t('NotificationOfNeed.Successively'),
    hideHeaderClose: false,
    centered: true,
    'body-class': 'p-2',
  })

  return split
}

export const mergeUpdateDictionary = toUpdateDictionary => {
  const updatedArray = []

  Object.keys(toUpdateDictionary).forEach(date => {
    Object.keys(toUpdateDictionary[date]).forEach(layerIndex => {
      const arrayToMerge = toUpdateDictionary[date][layerIndex]

      const value = Math.max(...arrayToMerge.map(x => x.noticeOfNeed.value))

      const noticeOfNeed = { ...arrayToMerge[0].noticeOfNeed, value }

      updatedArray.push({
        selected: arrayToMerge.map(x => x.selected),
        oldOrders: arrayToMerge[0].oldOrders,
        noticeOfNeed,
      })
    })
  })

  return Object.keys(toUpdateDictionary)
    .map(date =>
      Object.keys(toUpdateDictionary[date]).map(layerIndex => {
        const arrayToMerge = toUpdateDictionary[date][layerIndex]

        const value = Math.max(...arrayToMerge.map(x => x.noticeOfNeed.value))

        const noticeOfNeed = { ...arrayToMerge[0].noticeOfNeed, value }

        return {
          selected: arrayToMerge.map(x => x.selected),
          oldOrders: arrayToMerge[0].oldOrders,
          noticeOfNeed,
        }
      }),
    )
    .flat(1)
}

export const nextLayer = (currentDate, currentLayerIndex, layersLength) => {
  const date =
    currentLayerIndex + 1 > layersLength - 1
      ? moment(currentDate, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD')
      : currentDate
  const layerIndex = (currentLayerIndex + 1) % layersLength
  return { date, layerIndex }
}

export const findLastestConfig = updatedArray => {
  let date = moment(updatedArray[0].noticeOfNeed.date, 'YYYY-MM-DD')
  let { layerIndex } = updatedArray[0].noticeOfNeed

  updatedArray.forEach(({ noticeOfNeed }) => {
    if (date.isBefore(noticeOfNeed.date)) {
      date = moment(noticeOfNeed.date, 'YYYY-MM-DD')
      layerIndex = noticeOfNeed.layerIndex
      return
    }

    if (date.isSame(noticeOfNeed.date, 'day')) {
      layerIndex = Math.max(noticeOfNeed.layerIndex, layerIndex)
    }
  })

  return { date: date.format('YYYY-MM-DD'), layerIndex }
}
