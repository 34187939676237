var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","modal-class":"modal-primary min-h-800","size":"xl","visible":_vm.visible,"title":_vm.$t('NotificationOfNeed.LinkOrder')},on:{"change":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var close = ref.close;
var ok = ref.ok;
return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":close}},[_vm._v(" "+_vm._s(_vm.$t('Global.Retreat'))+" ")]),(!_vm.isInPast && _vm.canEditCell)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function () {
          _vm.submitMulti(ok)
        }}},[_vm._v(" "+_vm._s(_vm.$t('NotificationOfNeed.MultiSave'))+" ")]):_vm._e(),(!_vm.isInPast && _vm.canEditCell)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function () {
          _vm.submitSingle(ok)
        }}},[_vm._v(" "+_vm._s(_vm.$t('NotificationOfNeed.SingleSave'))+" ")]):_vm._e()]}}])},[_c('OrderTable',{attrs:{"selected":_vm.selected,"orders":_vm.orders,"orders-layer-booked-update":_vm.ordersLayerBookedUpdate,"disabled":_vm.isInPast || !_vm.canEditCell,"rest-rest":true},on:{"handle-change":_vm.handleChange,"selected":_vm.selectionChanged,"handle-reset-rest":_vm.restRest}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }