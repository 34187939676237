<template>
  <div>
    <div
      class="custom-search d-flex justify-content-end align-items-center mb-2 pt-2"
    >
      <div class="d-flex align-items-center">
        <b-form-input
          v-model="searchTerm"
          :placeholder="$t('Global.Search')"
          type="text"
          class="d-inline-block search"
        />
      </div>
    </div>
    <vue-good-table
      :columns="columns"
      :rows="ordersUpdated"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      @on-selected-rows-change="selectionChanged"
    >
      <div slot="emptystate" class="text-center">
        {{ $t('Global.Emptystate') }}
      </div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'selected'">
          <b-form-checkbox
            :checked="selected.includes(props.row.id)"
            :value="true"
            :disabled="disabled"
            class="custom-control-primary"
            @change="value => handleChange(value, props.row.id)"
          />
        </span>
        <span v-else-if="restRest && props.column.field === 'action'">
          <feather-icon
            v-if="props.row.rest !== 0"
            v-b-tooltip.hover.top="$t('NotificationOfNeed.RestReset')"
            icon="FastForwardIcon"
            class="hover-red"
            size="18"
            @click="handleRestRest(props.row)"
          />
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap px-2 pb-2">
          <div class="d-flex align-items-center mb-0 mt-1 flex-wrap">
            <span class="text-nowrap">
              {{ $t('Global.ShowingOneOf') }}
            </span>
            <v-select
              v-model="pageLength"
              class="mx-1"
              style="min-width: 75px"
              :clearable="false"
              :options="['15', '30', '50']"
              @input="value => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap">{{ $t('Global.EntriesShown') }}</span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { BPagination, BFormInput, BFormCheckbox } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BPagination,
    BFormInput,
    VueGoodTable,
    vSelect,
    BFormCheckbox,
  },

  props: {
    restRest: {
      type: Boolean,
      default: () => false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    orders: {
      type: Array,
      default: () => [],
    },
    ordersLayerBookedUpdate: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      searchTerm: '',
      pageLength: 30,
      columns: [
        {
          field: 'selected',
          label: this.$t('Global.Selection'),
          globalSearchDisabled: true,
          sortable: false,
        },
        {
          field: 'name',
          label: this.$t('Management.Orders.Name'),
        },
        {
          field: 'need',
          label: `${this.$t('Management.Orders.Need')} ${this.$t(
            'Management.Orders.PerLayer',
          )}`,
          type: 'number',
        },
        {
          field: 'layersCount',
          label: this.$t('Management.Orders.LayersCount'),
          type: 'number',
        },
        {
          field: 'rest',
          label: this.$t('NotificationOfNeed.OrdersRest'),
          type: 'number',
          formatFn: (rest, { layersCount }) => rest % layersCount,
        },
      ],
      selectedCopy: [],
    }
  },

  computed: {
    ordersUpdated() {
      const validOrders = this.orders.filter(
        order => order.active || this.selectedCopy.includes(order.id),
      )

      const updated = validOrders.map(order => {
        const orderLayerBookedUpdate = this.ordersLayerBookedUpdate.find(
          x => order.id === x.id,
        )

        const updatedValue = orderLayerBookedUpdate
          ? orderLayerBookedUpdate.value
          : 0
        const rest = (order.rest + updatedValue) % order.layersCount

        return {
          ...order,
          rest: rest === 0 ? 0 : Math.abs(rest - order.layersCount),
        }
      })

      return updated.sort((a, b) => b.rest - a.rest)
    },
  },

  mounted() {
    this.selectedCopy = JSON.parse(JSON.stringify(this.selected))

    if (this.restRest) {
      this.columns.push({
        field: 'action',
        label: this.$t('Global.Action'),
        globalSearchDisabled: true,
        sortable: false,
      })
    }
  },

  methods: {
    selectionChanged({ selectedRows }) {
      this.$emit('selected', selectedRows)
    },

    handleChange(value, id) {
      this.$emit('handle-change', { value, id })
    },

    handleRestRest(row) {
      this.$emit('handle-reset-rest', row)
    },
  },
}
</script>
