export const methodEnum = {
  minus: 'minus',
  plus: 'plus',
}

export const AttachOrderEnum = {
  split: 'split',
  fill: 'fill',
}

export const AttachOrderMethod = {
  parallel: 'parallel',
  successively: 'successively',
  none: 'none',
}

export const AdjustNoticeOfNeedType = {
  order: 'order',
  value: 'value',
  staffAvailable: 'staffAvailable',
}
