var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar"},[_c('div',{staticClass:"calendar-grid border-bottom py-1"},[_c('div'),_c('div'),_vm._l((_vm.weekDays),function(day){return _c('div',{key:day.date,staticClass:"text-center text-bold"},[_vm._v(" "+_vm._s(day.weekday)+" "+_vm._s(day.date)+" ")])}),_c('div',{staticClass:"text-center"},[_vm._v("∑")])],2),_vm._l((_vm.calendar),function(entry,calendarIndex){return _c('div',{key:((entry.layer) + "-" + (entry.index))},[(
        _vm.responsibleLayers[entry.layer].open &&
        _vm.responsibleLayers[entry.layer].lines[entry.index].active
      )?_c('div',{staticClass:"calendar-grid border-bottom"},[_c('div',{staticClass:"d-flex align-items-center border-right px-1"},[_vm._v(" "+_vm._s(entry.title)+" ")]),_c('div',_vm._l((_vm.responsibleLayers[entry.layer].layers),function(layer,i){return _c('div',{key:((entry.layer) + "-" + (entry.index) + "-" + i),staticClass:"calender-unit border-right",class:{
            'border-bottom border-bottom-dashed':
              i + 1 !== _vm.responsibleLayers[entry.layer].layers.length,
          }},[_vm._v(" "+_vm._s(layer)+" ")])}),0),_vm._l((entry.noticeOfNeed),function(notice,noticeOfNeedIndex){return _c('div',{key:notice.date},_vm._l((notice),function(layerNotice,i){return _c('div',{key:((entry.layer) + "-" + (entry.index) + "-" + i),staticClass:"calender-unit border-right align-items-stretch relative",class:{
            'border-bottom border-bottom-dashed':
              i + 1 !== _vm.responsibleLayers[entry.layer].layers.length,
          }},[_vm._t("default",[_vm._v(" "+_vm._s(layerNotice.date)+" ")],{"context":layerNotice,"config":{
              attachOrder: _vm.responsibleLayers[entry.layer].config.attachOrder,
              layer:
                _vm.responsibleLayers[entry.layer].config.layers[
                  layerNotice.layerIndex
                ],
              group:
                _vm.responsibleLayers[entry.layer].config.groups[
                  layerNotice.groupIndex
                ],
              block:
                _vm.responsibleLayers[entry.layer].config.blocks[
                  layerNotice.blockIndex
                ],
            },"valueChanged":_vm.calendarUntouched[calendarIndex].noticeOfNeed[
                noticeOfNeedIndex
              ][i].value !== layerNotice.value})],2)}),0)}),_c('div',_vm._l((_vm.responsibleLayers[entry.layer].layers),function(layer,i){return _c('div',{key:("sum-" + (entry.layer) + "-" + i),staticClass:"calender-sum",class:{
            'border-bottom border-bottom-dashed':
              i + 1 !== _vm.responsibleLayers[entry.layer].layers.length,
          }},[_vm._v(" "+_vm._s(_vm.calcRow[entry.layer][entry.index][i])+" ")])}),0)],2):_vm._e()])}),(_vm.calendar.length !== 0)?_c('div',{staticClass:"sum-divider border-bottom"}):_vm._e(),_vm._l((Object.keys(_vm.calcCol)),function(col){return _c('div',{key:col},[(_vm.responsibleLayers[col].open && _vm.calendar.length !== 0)?_c('div',{staticClass:"calendar-grid border-bottom"},[_c('div',{staticClass:"d-flex align-items-center border-right px-1"},[_vm._v(" ∑ "+_vm._s(_vm.$t('NotificationOfNeed.Sum'))+" "+_vm._s(_vm.responsibleLayers[col].name)+" ")]),_c('div',_vm._l((_vm.calcCol[col]),function(layer,layerIndex){return _c('div',{key:("layer-title-" + layerIndex),staticClass:"calender-unit border-right",class:{
            'border-bottom border-bottom-dashed':
              layerIndex + 1 !== _vm.calcCol[col].length,
          }},[_vm._v(" "+_vm._s(_vm.responsibleLayers[col].config.layers[layerIndex].name)+" ")])}),0),_vm._l(([0, 1, 2, 3, 4, 5, 6]),function(dayIndex){return _c('div',{key:("day-" + dayIndex)},_vm._l((_vm.calcCol[col]),function(layer,layerIndex){return _c('div',{key:("layer-" + layerIndex),staticClass:"calender-unit border-right align-items-center relative",class:{
            'border-bottom border-bottom-dashed':
              layerIndex + 1 !== _vm.calcCol[col].length,
          }},[_vm._v(" "+_vm._s(layer[dayIndex])+" ")])}),0)})],2):_vm._e()])}),(_vm.calendar.length === 0)?_c('div',{staticClass:"text-center mt-1"},[_vm._v(" "+_vm._s(_vm.$t('NotificationOfNeed.NoEntries'))+" ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }