import moment from 'moment'
import confirmPopup from '@/utility/scripts/confirm'

export default async function transmitWeek() {
  const wannaTransmit = await confirmPopup(this, {
    okText: this.$t('NotificationOfNeed.Transmit'),
    title: this.$t('NotificationOfNeed.Transmit'),
    text: this.$t('NotificationOfNeed.TransmitText', {
      time: `${this.weekStart} - ${this.weekEnd}`,
    }),
  })

  if (!wannaTransmit) return

  if (
    this.createCalendarPool.length !== 0 ||
    this.updateCalendarPool.length !== 0
  ) {
    const choice = await confirmPopup(this, {
      okText: this.$t('Global.Save'),
      title: this.$t('Global.Save'),
      text: this.$t('NotificationOfNeed.UnsavedChangesText'),
    })

    if (!choice) return
    await this.updateCalendar()
  }

  this.isLoading = true
  try {
    const toTransmit = []
    const keys = Object.keys(this.responsibleLayers)

    keys.forEach(key => {
      const layer = this.responsibleLayers[key]
      if (layer.open === false) return

      layer.lines.forEach((line, i) => {
        if (line.active) {
          toTransmit.push({ layer: key, lineIndex: i })
        }
      })
    })

    await this.$axios.patch(
      `notice-of-need-calendar/transmit-calendar/${moment(this.date).get(
        'year',
      )}/${this.calendarWeek}`,
      toTransmit,
    )
    await this.loadCalendar()
    this.$alert.success(
      this.$t('NotificationOfNeed.UpdateTransmit', {
        startDate: this.weekStart,
        endDate: this.weekEnd,
      }),
    )
  } catch (error) {
    this.$alert.error()
  }
  this.isLoading = false
}
