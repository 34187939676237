<template>
  <b-modal
    :visible="visible"
    :title="$t('NotificationOfNeed.AdjustOrder')"
    :ok-title="$t('Global.Ok')"
    :cancel-title="$t('Global.Retreat')"
    cancel-variant="outline-secondary"
    centered
    size="xl"
    @hide="close"
  >
    <OrderTable
      :disabled="false"
      :orders="ordersSortedLocally"
      :rest-rest="null"
      :selected="selected"
      :orders-layer-booked-update="ordersLayerBookedUpdate"
      @handle-change="handleChange"
    />

    <template #modal-footer="{ close: closeModal }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        @click="closeModal"
      >
        {{ $t('Global.Retreat') }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="summitAdjustedOrder"
      >
        {{ $t('Global.Ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue'
import OrderTable from '../order-table.vue'

import {
  promptParallel,
  promptSuccessively,
} from '../../utility/attachOrderMulti'

export default {
  components: {
    BModal,
    BButton,
    OrderTable,
  },

  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    ordersLayerBookedUpdate: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    visible: false,

    selected: [],

    ordersSortedLocally: [],
  }),

  computed: {
    ordersDictionary() {
      return this.orders.reduce((prev, curr) => {
        prev[curr.id] = curr
        return prev
      }, {})
    },
  },

  methods: {
    init(selected) {
      this.selected = selected

      this.ordersSortedLocally = JSON.parse(JSON.stringify(this.orders)).sort(
        (a, b) => {
          if (this.selected.includes(a.id)) return -1
          if (this.selected.includes(b.id)) return 1
          return 0
        },
      )

      this.visible = true
    },

    close() {
      this.$emit('close')
    },

    handleChange({ value, id }) {
      if (value) {
        this.selected.push(id)
        return
      }

      const index = this.selected.indexOf(id)
      if (index > -1) {
        this.selected.splice(index, 1)
      }
    },

    async getValue(totalNeed) {
      const userChoice = await promptParallel.call(this)
      if (userChoice === null) {
        this.close()
        return 0
      }

      if (userChoice === true) return totalNeed

      const sameValue = this.selected.every(
        orderId =>
          this.ordersDictionary[orderId].need ===
          this.ordersDictionary[this.selected[0]].need,
      )

      if (sameValue) {
        return this.ordersDictionary[this.selected[0]].need
      }

      const { value, choice } = await promptSuccessively.call(this, totalNeed)
      if (!choice) {
        this.hide()
        return 0
      }

      return value
    },

    async summitAdjustedOrder() {
      let totalNeed = this.selected.reduce((prev, curr) => {
        const { need } = this.ordersDictionary[curr]
        return prev + need
      }, 0)

      if (this.selected.length > 1) {
        totalNeed = await this.getValue(totalNeed)
      }

      this.$emit('adjust', {
        value: totalNeed,
        orders: this.selected,
      })
    },
  },
}
</script>
