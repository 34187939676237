<template>
  <div class="d-flex flex-column justify-content-between border-right">
    <div class="ml-2 mt-2">
      <h4 class="font-bold">{{ $t('NotificationOfNeed.Layers') }}</h4>

      <SideNavLayerControls :responsible-layers="responsibleLayers" />
    </div>
    <div
      v-if="showControls"
      class="d-flex flex-column align-items-start ml-2 mb-2"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mb-1 d-flex justify-content-center align-items-center"
        :disabled="!canUpdate"
        @click="$emit('submit')"
      >
        <feather-icon icon="SaveIcon" size="21" class="mr-1" />
        {{ $t('Global.Save') }}
      </b-button>
      <b-button
        v-if="isAdmin"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mb-1 d-flex justify-content-center align-items-center"
        @click="$emit('approve')"
      >
        <feather-icon icon="CheckCircleIcon" size="21" class="mr-1" />
        {{ $t('Global.Approve') }}
      </b-button>
      <b-button
        v-else
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="d-flex justify-content-center align-items-center"
        @click="$emit('transmit')"
      >
        <feather-icon icon="CheckCircleIcon" size="21" class="mr-1" />
        {{ $t('NotificationOfNeed.Transmit') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

import SideNavLayerControls from './side-nav-layer-controls.vue'

export default {
  components: {
    BButton,
    SideNavLayerControls,
  },

  props: {
    responsibleLayers: {
      type: Object,
      required: true,
    },
    canUpdate: {
      type: Boolean,
      required: true,
    },
    showControls: {
      type: Boolean,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
