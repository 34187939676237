<template>
  <div>
    <ValueModal
      ref="value-modal"
      :adjust-notice-of-need="adjustNoticeOfNeed"
      @adjust="adjust"
      @close="close"
    />
    <OrderModal
      ref="order-modal"
      :orders="orders"
      :orders-layer-booked-update="ordersLayerBookedUpdate"
      @adjust="adjust"
      @close="close"
    />
    <CutModal
      ref="cut-modal"
      :related-need="relatedNeed"
      :adjust-notice-of-need="adjustNoticeOfNeed"
      :staff-scheduling="staffScheduling"
      @cut="updateLessNeed"
      @close="close"
    />
    <StaffAvailableModal
      ref="staff-available-modal"
      :adjust-notice-of-need="adjustNoticeOfNeed"
      @adjust="adjustStaffAvailable"
      @close="close"
    />
  </div>
</template>

<script>
import { StaffSchedulingStatus } from '@/views/loggedin/staff-scheduling/enums'

import { AdjustNoticeOfNeedType } from '../../utility/enums'

import OrderModal from './order-modal.vue'
import ValueModal from './value-modal.vue'
import CutModal from './cut-modal.vue'
import StaffAvailableModal from './staff-available-modal.vue'

const StaffSchedulingOrder = [
  StaffSchedulingStatus.open,
  StaffSchedulingStatus.rejected,
  StaffSchedulingStatus.cancellationNoted,
  StaffSchedulingStatus.approved,
  StaffSchedulingStatus.canceled,
  StaffSchedulingStatus.transmitted,
]

export default {
  components: {
    OrderModal,
    ValueModal,
    CutModal,
    StaffAvailableModal,
  },

  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    ordersLayerBookedUpdate: {
      type: Array,
      required: true,
    },
    adjustNoticeOfNeed: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    AdjustNoticeOfNeedType,

    staffScheduling: [],
    relatedNoticeOfNeed: [],
  }),

  computed: {
    relatedNeed() {
      if (!this.staffScheduling) return 0
      return this.relatedNoticeOfNeed.reduce((prev, curr) => {
        if (curr.id === this.adjustNoticeOfNeed.id) return prev
        return prev + curr.value
      }, 0)
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    async updateMoreNeed({ value, orders }) {
      try {
        await this.$axios.patch(
          `notice-of-need/${this.adjustNoticeOfNeed.id}`,
          {
            value,
            orders,
          },
        )
        this.$emit('adjust-value-calendar')
      } catch {
        this.$alert.error()
      }
      this.close()
    },

    async updateLessNeed({ value, cutStaffScheduling, orders }) {
      try {
        await this.$axios.patch(
          `notice-of-need/adjust-need/${this.adjustNoticeOfNeed.id}`,
          {
            value,
            cutStaffScheduling: cutStaffScheduling || [],
            orders,
          },
        )
        this.$emit('adjust-value-calendar')
      } catch {
        this.$alert.error()
      }
      this.close()
    },

    async startFlow(type) {
      const { data } = await this.$axios.get(
        `notice-of-need/adjust-need/${this.adjustNoticeOfNeed.id}`,
      )

      this.staffScheduling = data.staffScheduling.sort((a, b) => {
        const aIndex = StaffSchedulingOrder.indexOf(a.status)
        const bIndex = StaffSchedulingOrder.indexOf(b.status)
        return aIndex - bIndex
      })

      this.relatedNoticeOfNeed = data.noticeOfNeed

      if (type === AdjustNoticeOfNeedType.value) {
        this.$refs['value-modal'].init(this.adjustNoticeOfNeed.value)
      }

      if (type === AdjustNoticeOfNeedType.order) {
        this.$refs['order-modal'].init([...this.adjustNoticeOfNeed.orders])
      }

      if (type === AdjustNoticeOfNeedType.staffAvailable) {
        this.$refs['staff-available-modal'].init(
          this.adjustNoticeOfNeed.staffAvailable,
        )
      }
    },

    async adjustStaffAvailable({ staffAvailable }) {
      try {
        await this.$axios.patch(
          `notice-of-need/${this.adjustNoticeOfNeed.id}`,
          {
            staffAvailable,
          },
        )
        this.$emit('adjust-value-calendar')
      } catch {
        this.$alert.error()
      } finally {
        this.close()
      }
    },

    async adjust({ value, orders }) {
      if (value > this.adjustNoticeOfNeed.value) {
        await this.updateMoreNeed({ value, orders })
        return
      }

      if (this.relatedNeed + value >= this.staffScheduling.length) {
        await this.updateLessNeed({ value, orders })
        return
      }

      this.$refs['cut-modal'].init({
        orders,
        value,
        layerContext: this.staffScheduling[0].layer,
      })
    },
  },
}
</script>
