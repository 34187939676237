<template>
  <b-modal
    centered
    modal-class="modal-primary min-h-800"
    size="xl"
    :visible="visible"
    :title="$t('NotificationOfNeed.LinkOrder')"
    @change="$emit('close')"
  >
    <OrderTable
      :selected="selected"
      :orders="orders"
      :orders-layer-booked-update="ordersLayerBookedUpdate"
      :disabled="isInPast || !canEditCell"
      :rest-rest="true"
      @handle-change="handleChange"
      @selected="selectionChanged"
      @handle-reset-rest="restRest"
    />

    <template #modal-footer="{ close, ok }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        @click="close"
      >
        {{ $t('Global.Retreat') }}
      </b-button>
      <b-button
        v-if="!isInPast && canEditCell"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="
          () => {
            submitMulti(ok)
          }
        "
      >
        {{ $t('NotificationOfNeed.MultiSave') }}
      </b-button>
      <b-button
        v-if="!isInPast && canEditCell"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="
          () => {
            submitSingle(ok)
          }
        "
      >
        {{ $t('NotificationOfNeed.SingleSave') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue'
import moment from 'moment'

import confirm from '@/utility/scripts/confirm'

import { AttachOrderMethod } from '../utility/enums'
import {
  promptSplit,
  promptParallel,
  promptSuccessively,
} from '../utility/attachOrderMulti'
import OrderTable from './order-table.vue'

export default {
  components: {
    BModal,
    BButton,
    OrderTable,
  },

  props: {
    noticeOfNeed: {
      type: Object,
      default: () => null,
    },
    orders: {
      type: Array,
      required: true,
    },
    ordersLayerBookedUpdate: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    oldOrders: [],
    selected: [],
  }),

  computed: {
    visible() {
      return !!this.noticeOfNeed
    },

    isInPast() {
      if (!this.noticeOfNeed) return true
      const today = moment().startOf('day').valueOf()
      return moment(this.noticeOfNeed.date, 'YYYY-MM-DD') < today
    },

    canEditCell() {
      if (!this.noticeOfNeed) return true
      const canApprove = this.$can(
        this.$acl.action.Approve,
        this.$acl.subjects.NoticeOfNeed,
      )

      if (this.noticeOfNeed.approved) return false
      if (this.noticeOfNeed.transmitted) return canApprove
      return true
    },

    ordersDictionary() {
      return this.orders.reduce((prev, curr) => {
        prev[curr.id] = curr
        return prev
      }, {})
    },
  },

  watch: {
    noticeOfNeed(noticeOfNeed) {
      if (!noticeOfNeed) return
      this.oldOrders = JSON.parse(JSON.stringify(noticeOfNeed.orders))
      this.selected = JSON.parse(JSON.stringify(noticeOfNeed.orders))
    },
  },

  methods: {
    async submitSingle(ok) {
      let parallelValue

      if (this.selected.length > 1) {
        const userChoice = await promptParallel.call(this)
        if (userChoice === null) return

        if (userChoice === false) {
          const sameValue = this.selected.every(
            orderId =>
              this.ordersDictionary[orderId].need ===
              this.ordersDictionary[this.selected[0]].need,
          )

          if (sameValue) {
            parallelValue = this.ordersDictionary[this.selected[0]].need
          } else {
            const suggestedValue = this.selected.reduce((prev, curr) => {
              const { need } = this.orders.find(x => x.id === curr)
              return prev + need
            }, 0)
            const { value, choice } = await promptSuccessively.call(
              this,
              suggestedValue,
            )
            if (!choice) return
            parallelValue = value
          }
        }
      }

      this.$emit(
        'submit-single',
        {
          selected: this.selected,
          noticeOfNeed: this.noticeOfNeed,
          oldOrders: this.oldOrders,
        },
        parallelValue,
      )
      ok()
    },
    async submitMulti(ok) {
      const fill = await promptSplit.call(this)
      if (fill === null) return

      let parallel = AttachOrderMethod.none

      if (this.selected.length > 1) {
        const choice = await promptParallel.call(this)
        if (choice === null) return
        parallel = choice
          ? AttachOrderMethod.parallel
          : AttachOrderMethod.successively
      }

      const content = JSON.parse(
        JSON.stringify({
          selected: this.selected,
          noticeOfNeed: this.noticeOfNeed,
          oldOrders: this.oldOrders,
          fill,
          parallel,
        }),
      )
      this.$emit('submit-multi', content)
      ok()
    },

    selectionChanged(selected) {
      this.selected = selected
    },

    handleChange({ value, id }) {
      if (value) {
        this.selected.push(id)
        return
      }

      const index = this.selected.indexOf(id)
      if (index > -1) {
        this.selected.splice(index, 1)
      }
    },

    async restRest(order) {
      const okText = this.$t('Global.Reset')
      const cancelText = this.$t('Global.Retreat')
      const title = this.$t('NotificationOfNeed.RestReset')
      const text = this.$t('NotificationOfNeed.RestResetText', {
        subject: order.name,
      })

      const wannaReset = await confirm(this, {
        okText,
        cancelText,
        title,
        text,
      })

      if (!wannaReset) return

      const index = this.ordersLayerBookedUpdate.findIndex(
        x => x.id === order.id,
      )

      if (index === -1) {
        this.ordersLayerBookedUpdate.push({ id: order.id, value: order.rest })
        return
      }

      this.ordersLayerBookedUpdate[index].value += order.rest
    },
  },
}
</script>

<style lang="scss">
@media screen and (min-width: 600px) {
  .min-h-800 .modal-content {
    min-height: 800px;
  }
}
</style>
