<template>
  <b-overlay :show="isLoading" :opacity="0.5" rounded="sm">
    <b-card no-body class="layout-grid">
      <Sidenav
        :responsible-layers="responsibleLayers"
        :can-update="canUpdate"
        :show-controls="calendar.length !== 0"
        :is-admin="isAdmin"
        @submit="updateCalendar"
        @approve="approveWeek"
        @transmit="transmittedWeek"
      />
      <div>
        <CalenderHeader
          :calendar-week="calendarWeek"
          :week-end="weekEnd"
          :week-start="weekStart"
          @previous-week="previousWeek"
          @next-week="nextWeek"
          @refresh-no-cache="refreshNoCache"
        />
        <Calendar
          :calendar="calendar"
          :date="date"
          :responsible-layers="responsibleLayers"
          :calendar-untouched="calendarUntouched"
        >
          <template v-slot="{ context, config }">
            <div
              v-if="
                context.layerIndex !== null &&
                context.blockIndex !== null &&
                context.groundIndex !== null
              "
              v-b-tooltip.hover.top="config.group.name"
              class="status-bar"
              :style="{
                backgroundColor: config.group.color,
              }"
            />
            <div
              v-if="
                context.layerIndex !== null &&
                context.blockIndex !== null &&
                context.groundIndex !== null
              "
              class="d-flex w-100 justify-content-center align-items-center mt-10px"
            >
              <div
                class="spin-button-group"
                @contextmenu.prevent="adjustValue($event, context, config)"
              >
                <div class="spin-button-group-item">
                  <feather-icon
                    v-b-tooltip.hover.top="$t('NotificationOfNeed.Need')"
                    icon="UserPlusIcon"
                    class="mr-50"
                  />
                  <div
                    v-if="isInPast(context.date) || !canEditCell(context)"
                    class="text-center min-w-73px"
                  >
                    <div v-if="context.approved" class="text-success">
                      {{ context.value }}
                    </div>
                    <div
                      v-else
                      :class="{
                        ['text-warning']: context.transmitted,
                      }"
                    >
                      {{ context.value }}
                    </div>
                  </div>
                  <Spinbutton
                    v-else-if="context.layerIndex !== null"
                    v-model="context.value"
                    :warning="!!context.transmitted"
                    :danger="isOverTheLimit(context, config)"
                    @input="
                      value => {
                        inputContent({ value }, context)
                        context.approved = undefined
                      }
                    "
                  />
                </div>
                <div
                  v-if="config.group.staffCap !== null"
                  class="spin-button-group-item"
                >
                  <feather-icon
                    v-b-tooltip.hover.top="
                      $t('NotificationOfNeed.StaffAvailable')
                    "
                    icon="UsersIcon"
                    class="mr-50"
                  />
                  <div
                    v-if="isInPast(context.date) || !canEditCell(context)"
                    class="text-center min-w-73px"
                  >
                    {{ context.staffAvailable }}
                  </div>
                  <Spinbutton
                    v-else-if="context.layerIndex !== null"
                    v-model="context.staffAvailable"
                    @input="
                      staffAvailable => {
                        inputContent({ staffAvailable }, context)
                        context.approved = undefined
                      }
                    "
                  />
                </div>
              </div>
              <feather-icon
                v-if="config.attachOrder"
                v-b-tooltip.hover.top="displayOrders(context.orders)"
                icon="FileTextIcon"
                size="19"
                class="order-icon"
                :style="{
                  color:
                    context.orders.length === 0 ? 'inherit' : 'var(--success)',
                }"
                @click="orderNoticeOfNeed = context"
              />
            </div>
            <div v-else />
          </template>
        </Calendar>
      </div>
    </b-card>
    <OrderModal
      :notice-of-need="orderNoticeOfNeed"
      :orders="orders"
      :orders-layer-booked-update="ordersLayerBookedUpdate"
      @submit-single="attachOrder"
      @submit-multi="attachOrderMulti"
      @close="orderNoticeOfNeed = null"
    />

    <AdjustNoticeOfNeed
      v-if="adjustNoticeOfNeed"
      ref="adjust-notice-of-need"
      :adjust-notice-of-need="adjustNoticeOfNeed"
      :orders="orders"
      :orders-layer-booked-update="ordersLayerBookedUpdate"
      @close="adjustNoticeOfNeed = null"
      @adjust-value-calendar="adjustValueCalendar"
    />

    <vue-context ref="menu">
      <li>
        <b-link
          class="d-flex align-items-center"
          @click="() => enterAdjustValue(AdjustNoticeOfNeedType.value)"
        >
          <feather-icon icon="SkipBackIcon" size="16" />
          <span class="ml-75">{{ $t('NotificationOfNeed.AdjustValue') }}</span>
        </b-link>
      </li>
      <li
        v-if="
          adjustNoticeOfNeedConfig &&
          adjustNoticeOfNeedConfig.group.staffCap !== null
        "
      >
        <b-link
          class="d-flex align-items-center"
          @click="() => enterAdjustValue(AdjustNoticeOfNeedType.staffAvailable)"
        >
          <feather-icon icon="SkipBackIcon" size="16" />
          <span class="ml-75">
            {{ $t('NotificationOfNeed.AdjustStaffAvailable') }}
          </span>
        </b-link>
      </li>
      <li
        v-if="adjustNoticeOfNeedConfig && adjustNoticeOfNeedConfig.attachOrder"
      >
        <b-link
          class="d-flex align-items-center"
          @click="() => enterAdjustValue(AdjustNoticeOfNeedType.order)"
        >
          <feather-icon icon="SkipBackIcon" size="16" />
          <span class="ml-75">{{ $t('NotificationOfNeed.AdjustOrder') }}</span>
        </b-link>
      </li>
    </vue-context>
  </b-overlay>
</template>

<script>
import { BCard, BOverlay, BLink } from 'bootstrap-vue'
import moment from 'moment'
import VueContext from 'vue-context'

import { isAdmin } from '@/libs/acl/ability'
import Spinbutton from '@/components/Spinbutton/Spinbutton.vue'
import unsavedChanges from '@/utility/scripts/unsavedChanges'

import AdjustNoticeOfNeed from './components/adjust-notice-of-need/adjust-notice-of-need.vue'
import Calendar from './components/calendar.vue'
import Sidenav from './components/sidenav.vue'
import OrderModal from './components/order-modal.vue'
import CalenderHeader from './components/calendar-header.vue'
import {
  loadOrders,
  refreshNoCache,
  load,
  loadCalendar,
  updateCalendar,
} from './utility/load'
import approveWeek from './utility/approve'
import transmittedWeek from './utility/transmit'
import {
  isAlreadyInPool,
  isAlreadyInUpdatePool,
  isAlreadyInCreatePool,
  createContent,
  updateContent,
  resetCalender,
  calcCalender,
  inputContent,
} from './utility/handleNeedOfNotice'
import { AdjustNoticeOfNeedType } from './utility/enums'

export default {
  components: {
    BCard,
    BOverlay,
    Calendar,
    Sidenav,
    Spinbutton,
    OrderModal,
    CalenderHeader,
    AdjustNoticeOfNeed,
    BLink,
    VueContext,
  },

  data: () => ({
    calendar: [],

    calendarUntouched: [],

    orderNoticeOfNeed: null,
    orders: [],

    ordersLayerBookedUpdate: [],
    updateCalendarPool: [],
    createCalendarPool: [],

    responsibleLayers: {},

    date: moment().valueOf(),

    isLoading: false,

    adjustNoticeOfNeed: null,
    adjustNoticeOfNeedConfig: null,

    AdjustNoticeOfNeedType,
  }),

  computed: {
    isAdmin() {
      return isAdmin()
    },
    calendarWeek() {
      return moment(this.date).week()
    },
    weekStart() {
      return moment(this.date).startOf('week').format('DD.MM.YYYY')
    },
    weekEnd() {
      return moment(this.date).endOf('week').format('DD.MM.YYYY')
    },
    canUpdate() {
      return (
        this.updateCalendarPool.length !== 0 ||
        this.createCalendarPool.length !== 0 ||
        this.ordersLayerBookedUpdate.length !== 0
      )
    },
    isInPast() {
      const today = moment().startOf('day').valueOf()
      return date => moment(date, 'YYYY-MM-DD') < today
    },
    displayOrders() {
      const dictionary = this.orders.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: curr.name }),
        {},
      )
      return ids => ids.map(x => dictionary[x]).join(', ')
    },
    canEditCell() {
      const canApprove = this.$can(
        this.$acl.action.Approve,
        this.$acl.subjects.NoticeOfNeed,
      )

      return context => {
        if (context.approved) return false
        if (context.transmitted) return canApprove
        return true
      }
    },
  },

  watch: {
    date() {
      this.loadCalendar()
    },

    canUpdate(value) {
      window.onbeforeunload = value ? () => 'confirm' : null
    },
  },

  async created() {
    this.loadOrders()
    await this.load()
    await this.loadCalendar()
  },

  async beforeRouteUpdate(to, from, next) {
    const stillLeave = await this.wannaLeave()
    if (stillLeave) {
      window.onbeforeunload = null
      next()
    }
  },

  async beforeRouteLeave(to, from, next) {
    const stillLeave = await this.wannaLeave()
    if (stillLeave) {
      window.onbeforeunload = null
      next()
    }
  },

  methods: {
    isOverTheLimit(context, config) {
      if (!this.isAdmin || !config.group.staffCap) return false

      const currentDayIndex = moment(context.date).isoWeekday() - 1

      let count = 0

      this.calendar
        .filter(x => x.layer === context.layer)
        .forEach(calendarRow => {
          calendarRow.noticeOfNeed.forEach((day, dayIndex) => {
            if (dayIndex !== currentDayIndex) return

            day.forEach(entry => {
              if (entry.groupIndex !== context.groupIndex) return

              count += entry.value
              count += entry.staffAvailable
            })
          })
        })

      return count > config.group.staffCap
    },

    adjustValueCalendar() {
      this.adjustNoticeOfNeed = null
      this.adjustNoticeOfNeedConfig = null
      this.loadCalendar()
    },

    adjustValue(event, context, config) {
      if (this.isInPast(context.date)) return

      if (!this.isAdmin) return

      this.adjustNoticeOfNeedConfig = config
      this.adjustNoticeOfNeed = JSON.parse(JSON.stringify(context))

      this.$refs.menu.open(event)
    },

    enterAdjustValue(type) {
      this.$refs['adjust-notice-of-need'].startFlow(type)
    },

    async wannaLeave() {
      if (this.canUpdate) {
        return await unsavedChanges(this)
      }
      return true
    },

    async refreshNoCache() {
      return await refreshNoCache.call(this)
    },

    async load(...params) {
      return await load.call(this, ...params)
    },

    async loadOrders() {
      return await loadOrders.call(this)
    },

    async loadCalendar(...params) {
      return await loadCalendar.call(this, ...params)
    },

    async attachOrder(...params) {
      const { attachOrder } = await import('./utility/handleOrders')
      return await attachOrder.call(this, ...params)
    },

    async attachOrderMulti(...params) {
      const { attachOrderMulti } = await import('./utility/handleOrders')
      return await attachOrderMulti.call(this, ...params)
    },

    async getUpdatedAttachOrder(...params) {
      const { getUpdatedAttachOrder } = await import('./utility/handleOrders')
      return await getUpdatedAttachOrder.call(this, ...params)
    },

    async attachOrderMultiFill(...params) {
      const { attachOrderMultiFill } = await import('./utility/handleOrders')
      return await attachOrderMultiFill.call(this, ...params)
    },

    async attachOrderMultiSplit(...params) {
      const { attachOrderMultiSplit } = await import('./utility/handleOrders')
      return await attachOrderMultiSplit.call(this, ...params)
    },

    async updateCalendar(...params) {
      return await updateCalendar.call(this, ...params)
    },

    isAlreadyInPool(...params) {
      return isAlreadyInPool.call(this, ...params)
    },

    isAlreadyInUpdatePool(...params) {
      return isAlreadyInUpdatePool.call(this, ...params)
    },

    isAlreadyInCreatePool(...params) {
      return isAlreadyInCreatePool.call(this, ...params)
    },

    createContent(...params) {
      return createContent.call(this, ...params)
    },

    updateContent(...params) {
      return updateContent.call(this, ...params)
    },

    resetCalender(...params) {
      return resetCalender.call(this, ...params)
    },

    calcCalender(...params) {
      return calcCalender.call(this, ...params)
    },

    inputContent(...params) {
      return inputContent.call(this, ...params)
    },

    async approveWeek() {
      return await approveWeek.call(this)
    },

    async transmittedWeek() {
      return await transmittedWeek.call(this)
    },

    nextWeek() {
      this.date = moment(this.date).add(1, 'week').valueOf()
    },

    previousWeek() {
      this.date = moment(this.date).subtract(1, 'week').valueOf()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/@core/scss/vue/libs/vue-context.scss';

.layout-grid {
  display: grid;
  grid-template-columns: 250px auto;
  overflow: auto;
}

.week-span {
  font-weight: bold;
  font-size: 18px;
  margin: 0;
}

.order-icon {
  position: absolute;
  right: 0.4rem;
  cursor: pointer;
}

.status-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
}

.spin-button-group {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.spin-button-group-item {
  display: flex;
  align-items: center;
  position: relative;
  svg {
    position: absolute;
    left: -1.5rem;
  }
}

.mt-10px {
  margin-top: 10px;
}

.min-w-73px {
  min-width: 73px;
}
</style>
