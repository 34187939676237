<template>
  <b-modal
    :visible="visible"
    :cancel-title="$t('Global.Retreat')"
    :title="$t('NotificationOfNeed.UnselectCutContext', unselectCutContext)"
    :ok-title="$t('Global.Ok')"
    cancel-variant="outline-secondary"
    centered
    :ok-disabled="selectedCount > expectedCount"
    @hide="$emit('close')"
  >
    <div>
      <div
        class="d-flex justify-content-center align-items-center my-1"
        :class="{
          'text-success': selectedCount <= expectedCount,
          'text-danger': selectedCount > expectedCount,
        }"
      >
        <span>
          {{ $t('NotificationOfNeed.UnselectCutSelected', { selectedCount }) }}
        </span>
        <feather-icon icon="ArrowRightIcon" size="16" class="mx-1" />
        <span>
          {{ $t('NotificationOfNeed.UnselectCutExpected', { expectedCount }) }}
        </span>
      </div>
      <div class="d-flex flex-column align-items-center">
        <div>
          <div
            v-for="entry in staffScheduling"
            :key="entry.id"
            class="checkbox-row"
          >
            <b-form-checkbox
              :disabled="isDisabled(entry.status)"
              :checked="selected[entry.id]"
              :value="true"
              @change="selected[entry.id] = !selected[entry.id]"
            >
              <Status :status="entry.status" />
              <span v-if="entry.type === StaffSchedulingType.Employee">
                {{ entry.employee.firstname }} {{ entry.employee.lastname }}
              </span>
              <span v-else-if="entry.serviceProviderName">
                <span>{{ entry.serviceProviderName }}</span>
                &nbsp;
                <small class="text-muted">
                  ({{ entry.serviceProvider.name }})
                </small>
              </span>
              <span v-else>
                {{ entry.serviceProvider.name }}
              </span>
            </b-form-checkbox>
          </div>
        </div>
      </div>
      <div v-if="!reduceIsPossible" class="text-center text-danger mt-2 mb-1">
        <small>{{ $t('NotificationOfNeed.NeedReduceImpossible') }}</small>
      </div>
    </div>

    <template #modal-footer="{ close }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        @click="close"
      >
        {{ $t('Global.Retreat') }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="updateLessNeed"
      >
        {{ $t('Global.Ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BFormCheckbox, BButton } from 'bootstrap-vue'
import moment from 'moment'
import {
  StaffSchedulingStatus,
  StaffSchedulingType,
} from '@/views/loggedin/staff-scheduling/enums'
import Status from '@/views/loggedin/staff-scheduling/components/content/status.vue'

export default {
  components: {
    BModal,
    BFormCheckbox,
    Status,
    BButton,
  },

  props: {
    relatedNeed: {
      type: Number,
      required: true,
    },
    adjustNoticeOfNeed: {
      type: Object,
      required: true,
    },
    staffScheduling: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    visible: false,
    currentNeed: 0,
    layerContext: null,

    selected: {},
    orders: undefined,

    StaffSchedulingType,
  }),

  computed: {
    expectedCount() {
      return this.relatedNeed + this.currentNeed
    },

    reduceIsPossible() {
      const disabled = this.staffScheduling.filter(
        x =>
          x.status === StaffSchedulingStatus.canceled ||
          x.status === StaffSchedulingStatus.approved ||
          x.status === StaffSchedulingStatus.transmitted,
      )

      return this.expectedCount >= disabled.length
    },

    selectedCount() {
      if (!this.staffScheduling) return 0
      return Object.values(this.staffScheduling).reduce((prev, curr) => {
        if (this.selected[curr.id]) return prev + 1
        return prev
      }, 0)
    },

    unselectCutContext() {
      if (!this.layerContext) return {}
      return {
        layer: this.layerContext.area,
        layerIndex:
          this.layerContext.configuration.layers[
            this.adjustNoticeOfNeed.layerIndex
          ].name,
        date: moment(this.adjustNoticeOfNeed.date, 'YYYY-MM-DD').format(
          'DD.MM.YYYY',
        ),
      }
    },
  },

  methods: {
    init({ value, layerContext, orders }) {
      this.currentNeed = value
      this.layerContext = layerContext
      this.orders = orders

      this.selected = this.staffScheduling.reduce((prev, curr) => {
        prev[curr.id] = true
        return prev
      }, {})

      this.visible = true
    },

    isDisabled(status) {
      return (
        status === StaffSchedulingStatus.canceled ||
        status === StaffSchedulingStatus.approved ||
        status === StaffSchedulingStatus.transmitted
      )
    },

    updateLessNeed() {
      this.$emit('cut', {
        orders: this.orders,
        value: this.currentNeed,
        cutStaffScheduling: Object.keys(this.selected).filter(
          id => !this.selected[id],
        ),
      })
    },
  },
}
</script>
