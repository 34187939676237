export function isAlreadyInPool(pool, context) {
  return this[pool].findIndex(
    x =>
      x.layer === context.layer &&
      x.date === context.date &&
      x.layerIndex === context.layerIndex &&
      x.lineIndex === context.lineIndex,
  )
}

export function isAlreadyInUpdatePool(context) {
  return this.isAlreadyInPool('updateCalendarPool', context)
}

export function isAlreadyInCreatePool(context) {
  return this.isAlreadyInPool('createCalendarPool', context)
}

export function createContent({ value, orders, staffAvailable }, context) {
  this.createCalendarPool.push({
    date: context.date,
    value: value ?? 0,
    layer: context.layer,
    layerIndex: context.layerIndex,
    lineIndex: context.lineIndex,
    orders: orders || [],
    staffAvailable: staffAvailable ?? 0,
  })
}

export function updateContent(
  { orders, value, staffAvailable },
  context,
  createPoolIndex,
) {
  const isAlreadyUpdateIndex = this.updateCalendarPool.findIndex(
    x => x.id === context.id,
  )
  if (isAlreadyUpdateIndex <= -1) {
    if (createPoolIndex === -1) {
      this.updateCalendarPool.push({
        id: context.id,
        value: value || context.value,
        orders: orders || context.orders,
        staffAvailable: staffAvailable || context.staffAvailable,
      })
      return
    }
    if (value !== null && value !== undefined) {
      this.createCalendarPool[createPoolIndex].value = value
    }
    if (staffAvailable) {
      this.createCalendarPool[createPoolIndex].staffAvailable = staffAvailable
    }
    if (orders) {
      this.createCalendarPool[createPoolIndex].orders = orders
    }
    return
  }

  if (orders) {
    this.updateCalendarPool[isAlreadyUpdateIndex].orders = orders
  }
  this.updateCalendarPool[isAlreadyUpdateIndex].value = value
  this.updateCalendarPool[isAlreadyUpdateIndex].staffAvailable = staffAvailable
}

export function resetCalender() {
  this.calendar = JSON.parse(
    JSON.stringify(this.calcCalender(this.calendarUntouched)),
  )
}

export function calcCalender(data) {
  const calender = data

  for (let rowIndex = 0; rowIndex < calender.length; rowIndex += 1) {
    const row = calender[rowIndex]

    for (let dayIndex = 0; dayIndex < row.noticeOfNeed.length; dayIndex += 1) {
      const day = row.noticeOfNeed[dayIndex]

      for (let layerIndex = 0; layerIndex < day.length; layerIndex += 1) {
        const noticeOfNeed = day[layerIndex]

        if (noticeOfNeed.id) {
          const isUpdated = this.updateCalendarPool.find(
            x => x.id === noticeOfNeed.id,
          )
          if (isUpdated) {
            calender[rowIndex].noticeOfNeed[dayIndex][layerIndex].value =
              isUpdated.value
            calender[rowIndex].noticeOfNeed[dayIndex][layerIndex].orders =
              isUpdated.orders
            calender[rowIndex].noticeOfNeed[dayIndex][
              layerIndex
            ].staffAvailable = isUpdated.staffAvailable
          }
        } else {
          const indexInPool = this.isAlreadyInCreatePool(noticeOfNeed)
          if (indexInPool > -1) {
            const { orders, value, staffAvailable } =
              this.createCalendarPool[indexInPool]
            calender[rowIndex].noticeOfNeed[dayIndex][layerIndex].value = value
            calender[rowIndex].noticeOfNeed[dayIndex][layerIndex].orders =
              orders
            calender[rowIndex].noticeOfNeed[dayIndex][
              layerIndex
            ].staffAvailable = staffAvailable
          }
        }
      }
    }
  }

  return calender
}

export function inputContent(values, context, reset) {
  const createPoolIndex = this.isAlreadyInCreatePool(context)
  if (context.id || createPoolIndex > -1) {
    this.updateContent(values, context, createPoolIndex)
  } else {
    this.createContent(values, context)
  }

  if (reset) {
    this.resetCalender()
  }
}
