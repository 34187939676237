var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isLoading,"opacity":0.5,"rounded":"sm"}},[_c('b-card',{staticClass:"layout-grid",attrs:{"no-body":""}},[_c('Sidenav',{attrs:{"responsible-layers":_vm.responsibleLayers,"can-update":_vm.canUpdate,"show-controls":_vm.calendar.length !== 0,"is-admin":_vm.isAdmin},on:{"submit":_vm.updateCalendar,"approve":_vm.approveWeek,"transmit":_vm.transmittedWeek}}),_c('div',[_c('CalenderHeader',{attrs:{"calendar-week":_vm.calendarWeek,"week-end":_vm.weekEnd,"week-start":_vm.weekStart},on:{"previous-week":_vm.previousWeek,"next-week":_vm.nextWeek,"refresh-no-cache":_vm.refreshNoCache}}),_c('Calendar',{attrs:{"calendar":_vm.calendar,"date":_vm.date,"responsible-layers":_vm.responsibleLayers,"calendar-untouched":_vm.calendarUntouched},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var _obj;

var context = ref.context;
var config = ref.config;return [(
              context.layerIndex !== null &&
              context.blockIndex !== null &&
              context.groundIndex !== null
            )?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(config.group.name),expression:"config.group.name",modifiers:{"hover":true,"top":true}}],staticClass:"status-bar",style:({
              backgroundColor: config.group.color,
            })}):_vm._e(),(
              context.layerIndex !== null &&
              context.blockIndex !== null &&
              context.groundIndex !== null
            )?_c('div',{staticClass:"d-flex w-100 justify-content-center align-items-center mt-10px"},[_c('div',{staticClass:"spin-button-group",on:{"contextmenu":function($event){$event.preventDefault();return _vm.adjustValue($event, context, config)}}},[_c('div',{staticClass:"spin-button-group-item"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('NotificationOfNeed.Need')),expression:"$t('NotificationOfNeed.Need')",modifiers:{"hover":true,"top":true}}],staticClass:"mr-50",attrs:{"icon":"UserPlusIcon"}}),(_vm.isInPast(context.date) || !_vm.canEditCell(context))?_c('div',{staticClass:"text-center min-w-73px"},[(context.approved)?_c('div',{staticClass:"text-success"},[_vm._v(" "+_vm._s(context.value)+" ")]):_c('div',{class:( _obj = {}, _obj['text-warning'] = context.transmitted, _obj )},[_vm._v(" "+_vm._s(context.value)+" ")])]):(context.layerIndex !== null)?_c('Spinbutton',{attrs:{"warning":!!context.transmitted,"danger":_vm.isOverTheLimit(context, config)},on:{"input":function (value) {
                      _vm.inputContent({ value: value }, context)
                      context.approved = undefined
                    }},model:{value:(context.value),callback:function ($$v) {_vm.$set(context, "value", $$v)},expression:"context.value"}}):_vm._e()],1),(config.group.staffCap !== null)?_c('div',{staticClass:"spin-button-group-item"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                    _vm.$t('NotificationOfNeed.StaffAvailable')
                  ),expression:"\n                    $t('NotificationOfNeed.StaffAvailable')\n                  ",modifiers:{"hover":true,"top":true}}],staticClass:"mr-50",attrs:{"icon":"UsersIcon"}}),(_vm.isInPast(context.date) || !_vm.canEditCell(context))?_c('div',{staticClass:"text-center min-w-73px"},[_vm._v(" "+_vm._s(context.staffAvailable)+" ")]):(context.layerIndex !== null)?_c('Spinbutton',{on:{"input":function (staffAvailable) {
                      _vm.inputContent({ staffAvailable: staffAvailable }, context)
                      context.approved = undefined
                    }},model:{value:(context.staffAvailable),callback:function ($$v) {_vm.$set(context, "staffAvailable", $$v)},expression:"context.staffAvailable"}}):_vm._e()],1):_vm._e()]),(config.attachOrder)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.displayOrders(context.orders)),expression:"displayOrders(context.orders)",modifiers:{"hover":true,"top":true}}],staticClass:"order-icon",style:({
                color:
                  context.orders.length === 0 ? 'inherit' : 'var(--success)',
              }),attrs:{"icon":"FileTextIcon","size":"19"},on:{"click":function($event){_vm.orderNoticeOfNeed = context}}}):_vm._e()],1):_c('div')]}}])})],1)],1),_c('OrderModal',{attrs:{"notice-of-need":_vm.orderNoticeOfNeed,"orders":_vm.orders,"orders-layer-booked-update":_vm.ordersLayerBookedUpdate},on:{"submit-single":_vm.attachOrder,"submit-multi":_vm.attachOrderMulti,"close":function($event){_vm.orderNoticeOfNeed = null}}}),(_vm.adjustNoticeOfNeed)?_c('AdjustNoticeOfNeed',{ref:"adjust-notice-of-need",attrs:{"adjust-notice-of-need":_vm.adjustNoticeOfNeed,"orders":_vm.orders,"orders-layer-booked-update":_vm.ordersLayerBookedUpdate},on:{"close":function($event){_vm.adjustNoticeOfNeed = null},"adjust-value-calendar":_vm.adjustValueCalendar}}):_vm._e(),_c('vue-context',{ref:"menu"},[_c('li',[_c('b-link',{staticClass:"d-flex align-items-center",on:{"click":function () { return _vm.enterAdjustValue(_vm.AdjustNoticeOfNeedType.value); }}},[_c('feather-icon',{attrs:{"icon":"SkipBackIcon","size":"16"}}),_c('span',{staticClass:"ml-75"},[_vm._v(_vm._s(_vm.$t('NotificationOfNeed.AdjustValue')))])],1)],1),(
        _vm.adjustNoticeOfNeedConfig &&
        _vm.adjustNoticeOfNeedConfig.group.staffCap !== null
      )?_c('li',[_c('b-link',{staticClass:"d-flex align-items-center",on:{"click":function () { return _vm.enterAdjustValue(_vm.AdjustNoticeOfNeedType.staffAvailable); }}},[_c('feather-icon',{attrs:{"icon":"SkipBackIcon","size":"16"}}),_c('span',{staticClass:"ml-75"},[_vm._v(" "+_vm._s(_vm.$t('NotificationOfNeed.AdjustStaffAvailable'))+" ")])],1)],1):_vm._e(),(_vm.adjustNoticeOfNeedConfig && _vm.adjustNoticeOfNeedConfig.attachOrder)?_c('li',[_c('b-link',{staticClass:"d-flex align-items-center",on:{"click":function () { return _vm.enterAdjustValue(_vm.AdjustNoticeOfNeedType.order); }}},[_c('feather-icon',{attrs:{"icon":"SkipBackIcon","size":"16"}}),_c('span',{staticClass:"ml-75"},[_vm._v(_vm._s(_vm.$t('NotificationOfNeed.AdjustOrder')))])],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }