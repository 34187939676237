<template>
  <div
    class="border-bottom p-2 d-flex align-items-center justify-content-between"
  >
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center mr-2">
        <feather-icon
          icon="ChevronLeftIcon"
          class="hover-grey"
          size="24"
          @click="$emit('previous-week')"
        />
        <feather-icon
          icon="ChevronRightIcon"
          class="hover-grey"
          size="24"
          @click="$emit('next-week')"
        />
      </div>
      <div>
        <h3 class="week-span">{{ weekStart }} - {{ weekEnd }}</h3>
        <div class="text-center">
          {{ $t('Dates.CalendarWeek.Short') }}{{ calendarWeek }}
        </div>
      </div>
    </div>
    <div>
      <feather-icon
        icon="RefreshCcwIcon"
        class="hover-grey"
        size="24"
        @click="$emit('refresh-no-cache')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    weekStart: {
      type: String,
      required: true,
    },
    weekEnd: {
      type: String,
      required: true,
    },
    calendarWeek: {
      type: Number,
      required: true,
    },
  },
}
</script>
