<template>
  <div class="d-flex align-items-center justify-content-center">
    <div
      class="d-flex align-items-center justify-content-center border rounded bg-white"
      :class="{
        'border-color-warning': warning,
        'border-color-success': success,
        'border-color-danger': danger,
      }"
    >
      <div class="icon-controls" @click="subtract">-</div>
      <div class="bar border-right" />
      <input
        type="number"
        :value="value"
        :style="{ color: inputColorStyle }"
        @input="updateValue"
      />
      <div class="bar border-left" />
      <div class="icon-controls" @click="add">+</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomSpinbutton',

  props: {
    value: {
      type: Number,
      required: true,
    },
    success: {
      type: Boolean,
      default: () => false,
    },
    danger: {
      type: Boolean,
      default: () => false,
    },
    warning: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      content: this.value,
    }
  },

  computed: {
    inputColorStyle() {
      if (this.success) return 'var(--success)'
      if (this.danger) return 'var(--danger)'
      if (this.warning) return 'var(--warning)'
      return 'inherit'
    },
  },

  watch: {
    content(content) {
      this.$emit('input', content)
    },
    value(value) {
      this.content = value
    },
  },

  mounted() {
    this.content = this.value
  },

  methods: {
    add() {
      this.content += 1
    },
    subtract() {
      if (this.value - 1 >= 0) {
        this.content -= 1
      }
    },
    updateValue(event) {
      const { value } = event.target
      const number = parseInt(value, 10)
      if (!number) return
      this.content = number < 0 ? 0 : number
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  max-width: 35px;
  height: 15px;
  border: none;
  text-align: center;
  outline: none;
}

.icon-controls {
  cursor: pointer;
  user-select: none;
  margin-inline: 5px;
  &:hover {
    color: #222;
  }
}

.bar {
  display: flex;
  align-self: stretch;
  margin-top: 4px;
  margin-bottom: 4px;
}

.border-color-warning {
  border-color: var(--warning) !important;
}
.border-color-success {
  border-color: var(--success) !important;
}
.border-color-danger {
  border-color: var(--danger) !important;
}

.border-color-warning .icon-controls {
  color: var(--warning);
}
.border-color-success .icon-controls {
  color: var(--success);
}
.border-color-danger .icon-controls {
  color: var(--danger);
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
