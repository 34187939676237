<template>
  <div class="calendar">
    <div class="calendar-grid border-bottom py-1">
      <div />
      <div />
      <div
        v-for="day in weekDays"
        :key="day.date"
        class="text-center text-bold"
      >
        {{ day.weekday }} {{ day.date }}
      </div>
      <div class="text-center">∑</div>
    </div>
    <div
      v-for="(entry, calendarIndex) in calendar"
      :key="`${entry.layer}-${entry.index}`"
    >
      <div
        v-if="
          responsibleLayers[entry.layer].open &&
          responsibleLayers[entry.layer].lines[entry.index].active
        "
        class="calendar-grid border-bottom"
      >
        <div class="d-flex align-items-center border-right px-1">
          {{ entry.title }}
        </div>
        <div>
          <div
            v-for="(layer, i) in responsibleLayers[entry.layer].layers"
            :key="`${entry.layer}-${entry.index}-${i}`"
            class="calender-unit border-right"
            :class="{
              'border-bottom border-bottom-dashed':
                i + 1 !== responsibleLayers[entry.layer].layers.length,
            }"
          >
            {{ layer }}
          </div>
        </div>
        <div
          v-for="(notice, noticeOfNeedIndex) in entry.noticeOfNeed"
          :key="notice.date"
        >
          <div
            v-for="(layerNotice, i) in notice"
            :key="`${entry.layer}-${entry.index}-${i}`"
            class="calender-unit border-right align-items-stretch relative"
            :class="{
              'border-bottom border-bottom-dashed':
                i + 1 !== responsibleLayers[entry.layer].layers.length,
            }"
          >
            <slot
              :context="layerNotice"
              :config="{
                attachOrder: responsibleLayers[entry.layer].config.attachOrder,
                layer:
                  responsibleLayers[entry.layer].config.layers[
                    layerNotice.layerIndex
                  ],
                group:
                  responsibleLayers[entry.layer].config.groups[
                    layerNotice.groupIndex
                  ],
                block:
                  responsibleLayers[entry.layer].config.blocks[
                    layerNotice.blockIndex
                  ],
              }"
              :value-changed="
                calendarUntouched[calendarIndex].noticeOfNeed[
                  noticeOfNeedIndex
                ][i].value !== layerNotice.value
              "
            >
              {{ layerNotice.date }}
            </slot>
          </div>
        </div>
        <div>
          <div
            v-for="(layer, i) in responsibleLayers[entry.layer].layers"
            :key="`sum-${entry.layer}-${i}`"
            class="calender-sum"
            :class="{
              'border-bottom border-bottom-dashed':
                i + 1 !== responsibleLayers[entry.layer].layers.length,
            }"
          >
            {{ calcRow[entry.layer][entry.index][i] }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="calendar.length !== 0" class="sum-divider border-bottom" />
    <div v-for="col in Object.keys(calcCol)" :key="col">
      <div
        v-if="responsibleLayers[col].open && calendar.length !== 0"
        class="calendar-grid border-bottom"
      >
        <div class="d-flex align-items-center border-right px-1">
          ∑ {{ $t('NotificationOfNeed.Sum') }} {{ responsibleLayers[col].name }}
        </div>
        <div>
          <div
            v-for="(layer, layerIndex) in calcCol[col]"
            :key="`layer-title-${layerIndex}`"
            class="calender-unit border-right"
            :class="{
              'border-bottom border-bottom-dashed':
                layerIndex + 1 !== calcCol[col].length,
            }"
          >
            {{ responsibleLayers[col].config.layers[layerIndex].name }}
          </div>
        </div>
        <div v-for="dayIndex in [0, 1, 2, 3, 4, 5, 6]" :key="`day-${dayIndex}`">
          <div
            v-for="(layer, layerIndex) in calcCol[col]"
            :key="`layer-${layerIndex}`"
            class="calender-unit border-right align-items-center relative"
            :class="{
              'border-bottom border-bottom-dashed':
                layerIndex + 1 !== calcCol[col].length,
            }"
          >
            {{ layer[dayIndex] }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="calendar.length === 0" class="text-center mt-1">
      {{ $t('NotificationOfNeed.NoEntries') }}
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    calendar: {
      type: Array,
      required: true,
    },
    calendarUntouched: {
      type: Array,
      required: true,
    },
    date: {
      type: Number,
      required: true,
    },
    responsibleLayers: {
      type: Object,
      required: true,
    },
  },

  computed: {
    calcRow() {
      const rows = {}

      Object.keys(this.responsibleLayers).forEach(layerKey => {
        const layer = this.responsibleLayers[layerKey]

        rows[layerKey] = []
        layer.lines.forEach((line, lineIndex) => {
          rows[layerKey][lineIndex] = []
          layer.config.layers.forEach((_, index) => {
            rows[layerKey][lineIndex][index] = 0
          })
        })
      })

      this.calendar.forEach(calendarRow => {
        calendarRow.noticeOfNeed.forEach(noticeOfNeeds => {
          noticeOfNeeds.forEach(entry => {
            rows[entry.layer][entry.lineIndex][entry.layerIndex] += entry.value
          })
        })
      })

      return rows
    },

    calcCol() {
      const cols = {}

      Object.keys(this.responsibleLayers).forEach(layerKey => {
        const layer = this.responsibleLayers[layerKey]

        cols[layerKey] = []
        layer.config.layers.forEach((_, index) => {
          cols[layerKey][index] = [0, 0, 0, 0, 0, 0, 0]
        })
      })

      this.calendar.forEach(calendarRow => {
        calendarRow.noticeOfNeed.forEach(noticeOfNeeds => {
          noticeOfNeeds.forEach(entry => {
            const day = moment(entry.date)
            const dayIndex = (day.isoWeekday() - 1) % 7
            if (
              this.responsibleLayers[entry.layer].lines[entry.lineIndex].active
            ) {
              if (cols[entry.layer][entry.layerIndex]) {
                cols[entry.layer][entry.layerIndex][dayIndex] += entry.value
              }
            }
          })
        })
      })
      return cols
    },

    weekDays() {
      const weekdays = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ]

      const date = moment(this.date).startOf('week').subtract(1, 'day')
      return Object.values(weekdays).map(weekday => ({
        weekday: this.$t(`Dates.Week.Short.${weekday}`),
        date: date.add(1, 'day').format('DD.MM.YYYY'),
      }))
    },
  },
}
</script>

<style lang="scss" scoped>
.calendar {
  min-height: 600px;
  width: 1344px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: 170px 140px repeat(7, 1fr) 50px;
  grid-template-rows: auto;
}

.sum-divider {
  height: 5px;
}

$minHeight: 75px;

.calender-sum {
  min-height: $minHeight;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calender-unit {
  min-height: $minHeight;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 130px;
}

.text-bold {
  font-weight: 600;
}

.border-bottom-dashed {
  border-bottom-style: dashed !important;
}
</style>
