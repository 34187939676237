<template>
  <b-modal
    :visible="visible"
    :title="$t('NotificationOfNeed.AdjustStaffAvailable')"
    centered
    @hide="$emit('close')"
  >
    <validation-observer ref="rules">
      <validation-provider
        #default="{ errors }"
        :name="$t('NotificationOfNeed.StaffAvailable')"
        rules="required|integer"
      >
        <div
          class="d-flex align-items-center flex-row-reverse justify-content-around mt-2"
        >
          <div>
            <b-form-input
              v-model="adjustedStaffAvailable"
              type="number"
              number
              :state="errors.length > 0 ? false : null"
              :placeholder="adjustNoticeOfNeed.staffAvailable.toString()"
            />
          </div>
          <div>
            <feather-icon icon="ArrowRightIcon" size="20" />
          </div>
          <div>
            <b-form-input :value="adjustNoticeOfNeed.staffAvailable" disabled />
          </div>
        </div>
        <div class="d-flex justify-content-center small-error">
          <small class="text-danger">{{ errors[0] }}</small>
        </div>
      </validation-provider>
    </validation-observer>

    <template #modal-footer="{ close }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        @click="close"
      >
        {{ $t('Global.Retreat') }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :disabled="adjustedStaffAvailable === adjustNoticeOfNeed.staffAvailable"
        variant="primary"
        @click="summitAdjustedValue"
      >
        {{ $t('Global.Ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BFormInput, BButton } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer } from '@validations'

export default {
  name: 'StaffAvailableModal',

  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormInput,
    BButton,
  },

  props: {
    adjustNoticeOfNeed: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    visible: false,

    adjustedStaffAvailable: null,

    required,
    integer,
  }),

  methods: {
    init(defaultValue) {
      this.adjustedStaffAvailable = defaultValue
      this.visible = true
    },

    hide() {
      this.visible = false
    },

    async validate() {
      try {
        const isValid = await this.$refs.rules.validate()
        return isValid
      } catch (error) {
        this.$alert.invalid()
        return false
      }
    },

    async summitAdjustedValue() {
      if (!(await this.validate())) return
      this.$emit('adjust', {
        staffAvailable: this.adjustedStaffAvailable,
        hide: this.hide,
      })
    },
  },
}
</script>
