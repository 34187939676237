<template>
  <div>
    <div
      v-for="departmentGroup in groupedLayersByDepartment"
      :key="departmentGroup.key"
      class="pt-1"
    >
      <b-form-checkbox
        v-if="departmentCheckbox[departmentGroup.key]"
        :checked="departmentCheckbox[departmentGroup.key].checked"
        :value="true"
        :indeterminate.sync="
          departmentCheckbox[departmentGroup.key].indeterminate
        "
        @change="toggleDepartment($event, departmentGroup)"
      >
        <h4>{{ departmentGroup.department }}</h4>
      </b-form-checkbox>

      <div class="pl-2">
        <div
          v-for="responsibility in departmentGroup.layers"
          :key="responsibility.id"
        >
          <b-form-checkbox
            :checked="responsibility.open"
            :value="true"
            :indeterminate.sync="responsibility.indeterminate"
            @change="toggleLayer($event, responsibility, departmentGroup)"
          >
            <h5>{{ responsibility.name }}</h5>
          </b-form-checkbox>
          <ul class="list-style-none">
            <li
              v-for="(line, i) in responsibility.lines"
              :key="`${responsibility.id}-${i}`"
              class="pt-05"
            >
              <b-form-checkbox
                :checked="line.active"
                :value="true"
                @change="value => toggleLine(responsibility.id, i, value)"
              >
                {{ line.name }}
              </b-form-checkbox>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'

const noDepartmentAssignedKey = 'no-department-assigned'

export default {
  name: 'SideNavLayerControls',

  components: {
    BFormCheckbox,
  },

  props: {
    responsibleLayers: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    departmentCheckbox: {},
  }),

  computed: {
    groupedLayersByDepartment() {
      const groupedLayers = []

      const map = this.getGroupedLayersByDepartmentMap()

      Array.from(map).forEach(([department, layers]) => {
        groupedLayers.push({
          key: department,
          department:
            department === noDepartmentAssignedKey
              ? this.$t('StaffScheduling.NoDepartmentAssigned')
              : department,
          layers,
        })
      })

      groupedLayers.sort((a, b) => {
        if (a.key === noDepartmentAssignedKey) {
          return 1
        }

        if (b.key === noDepartmentAssignedKey) {
          return -1
        }

        return a.department.localeCompare(b.department)
      })

      return groupedLayers
    },

    layerIds() {
      return Object.keys(this.responsibleLayers)
    },
  },

  watch: {
    layerIds() {
      this.setDepartmentCheckbox()
    },
  },

  mounted() {
    this.setDepartmentCheckbox()
  },

  methods: {
    setDepartmentCheckbox() {
      const map = this.getGroupedLayersByDepartmentMap()

      map.forEach((value, department) => {
        if (this.departmentCheckbox[department] !== undefined) {
          return
        }

        this.$set(this.departmentCheckbox, department, {
          checked: true,
          indeterminate: false,
        })
      })
    },

    getGroupedLayersByDepartmentMap() {
      return Object.values(this.responsibleLayers).reduce((acc, layerCtx) => {
        const key = layerCtx.department || noDepartmentAssignedKey

        if (!acc.has(key)) {
          acc.set(key, [])
        }

        acc.get(key).push(layerCtx)
        return acc
      }, new Map())
    },

    toggleLine(layerKey, lineIndex, value) {
      this.responsibleLayers[layerKey].lines[lineIndex].active = value

      const someActive = this.responsibleLayers[layerKey].lines.some(
        x => x.active,
      )
      if (someActive) {
        this.responsibleLayers[layerKey].open = true
        const allActive = this.responsibleLayers[layerKey].lines.every(
          x => x.active,
        )
        this.$nextTick(() => {
          this.responsibleLayers[layerKey].indeterminate = !allActive
        })
        return
      }

      this.responsibleLayers[layerKey].indeterminate = false
      this.$nextTick(() => {
        this.responsibleLayers[layerKey].open = false
      })
    },

    toggleLayer(checked, layerContext, departmentGroup) {
      layerContext.open = checked

      layerContext.lines.forEach((_, i) => {
        layerContext.lines[i].active = checked
      })

      const someActive = departmentGroup.layers.some(x => x.open)

      if (someActive) {
        this.departmentCheckbox[departmentGroup.key].checked = true
        const allActive = departmentGroup.layers.every(x => x.open)

        this.$nextTick(() => {
          this.departmentCheckbox[departmentGroup.key].indeterminate =
            !allActive
          this.departmentCheckbox[departmentGroup.key].checked = true
        })
        return
      }

      this.departmentCheckbox[departmentGroup.key].indeterminate = false
      this.$nextTick(() => {
        this.departmentCheckbox[departmentGroup.key].checked = false
      })
    },

    toggleDepartment(checked, departmentGroup) {
      departmentGroup.layers.forEach(layer => {
        layer.open = checked
        layer.indeterminate = false

        layer.lines.forEach((_, i) => {
          layer.lines[i].active = checked
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.list-style-none {
  list-style-type: none;
  padding-inline-start: 1.5rem;
}

.pt-05 {
  padding-bottom: 0.4rem;
}
</style>
